// import "styles/components/cards/primaryCard.scss";
import "styles/components/cards/primaryCard.scss";
interface Props {
  children: JSX.Element | JSX.Element[];
}

const PrimaryCard = ({
  children,
  ...props
}: Props &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >) => {
  return (
    <div {...props} className={`primaryCardWrapper ${props.className}`}>
      {children}
    </div>
  );
};

export default PrimaryCard;
