import { RootRoutes } from "App";
import PrimaryCard from "components/cards/PrimaryCard";
import { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import "styles/pages/checkout.scss";
import { Ad } from "../../../server/src/models/Ad";
import { ConfirmBookingAPI } from "api";
import { AdOption } from "../../../server/src/models/AdsOption";

const CheckoutPage = (): JSX.Element => {
  const { id } = useParams();

  if (!id) {
    return <Navigate to="/" />;
  }

  const [bookedAd, setBookedAd] = useState<Ad | null>(null);
  const [, setBookedAdChildren] = useState<Ad[]>([]);

  const confirmBookedAd = async () => {
    const { response, errors } = await ConfirmBookingAPI({
      stripeId: id,
    });

    if (errors) {
      errors.forEach((error) => toast.error(error.message));
      return;
    }

    if (!response || !response.success) {
      toast.error("Server not responding properly");
      return;
    }

    setBookedAd(response.ad);
    setBookedAdChildren(response.children);
  };

  useEffect(() => {
    confirmBookedAd();
  }, [id]);

  return (
    <div className="checkoutPage__wrapper">
      <div className="checkoutPage__content">
        <PrimaryCard className="checkoutPage__content__browserClassified">
          <h1 className="ts1 checkoutPage__content__browserClassified__title">
            Browser Classifieds
          </h1>
        </PrimaryCard>
        <PrimaryCard className="checkoutPage__content__congrats">
          {!bookedAd ? (
            <div className="loading_wrapper">
              <div className="loading">Loading ...</div>
            </div>
          ) : (
            <>
              <h1 className="ts1 checkoutPage__content__congrats__title">
                Congratulations !
              </h1>

              <p className="ts2 checkoutPage__content__congrats__body">
                Thanks for choosing Browser Classified.
              </p>

              <h1 className="ts1 checkoutPage__content__congrats__title">
                Check your email to fill in your classified form.
                <br />({bookedAd.email})
              </h1>

              <Link to={RootRoutes.home} className="ts2 continueToHomePage">
                Continue to home page
              </Link>
            </>
          )}
        </PrimaryCard>
        {!bookedAd ? (
          <div className="checkoutPage__content__ticket">
            <div className="loading">Loading ...</div>
          </div>
        ) : (
          <PrimaryCard className="checkoutPage__content__ticket">
            <h1
              className="ts1 checkoutPage__content__ticket__title"
              dangerouslySetInnerHTML={{
                __html: (bookedAd.option as AdOption).title || "Option Title",
              }}
            />
            <p className="ts2 checkoutPage__content__ticket__body">
              Booked Successfully
            </p>

            <div className="checkoutPage__content__ticket__infoWrapper">
              <p className="ts1 price">
                $ {((bookedAd.option as AdOption).price / 100).toFixed(2)}
              </p>
            </div>
          </PrimaryCard>
        )}
      </div>
    </div>
  );
};

export { CheckoutPage as Checkout };
