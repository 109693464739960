import { toast } from "react-toastify";
import "styles/components/common/calendar.scss";

interface Props {
  selectedDates: Date[];
  bookedDays: string[];
  setSelectedDates: React.Dispatch<React.SetStateAction<Date[]>>;
}

export const Calendar = ({
  selectedDates,
  bookedDays,
  setSelectedDates,
}: Props): JSX.Element => {
  const currentDate = new Date(new Date().setDate(new Date().getDate() + 1));
  const dates = Array(5)
    .fill(0)
    .map((_, i) =>
      Array(7)
        .fill(0)
        .map((_, j) => {
          const day = currentDate.getDay() - j;
          const date = new Date().setDate(currentDate.getDate() - day + 7 * i);
          return new Date(date);
        })
    );

  const bookedDates = bookedDays.map((date) => new Date(date));

  const onDateClick = (date: Date) => {
    if (
      bookedDates
        .map((date) => date.toDateString())
        .includes(date.toDateString()) ||
      date < currentDate
    ) {
      return toast.error("This date is already bought");
    }

    if (
      selectedDates
        .map((date) => date.toDateString())
        .includes(date.toDateString())
    ) {
      setSelectedDates((prev) =>
        prev.filter((d) => d.toDateString() !== date.toDateString())
      );
    } else {
      setSelectedDates(() => [date]);
    }
  };

  return (
    <div className="mainCalendarWrapper">
      <div className="mainCalendarWrapper__content">
        <div className="days ts2">
          <div className="days__day deactivated">Sun</div>
          <div className="days__day">Mon</div>
          <div className="days__day">Tue</div>
          <div className="days__day">Wed</div>
          <div className="days__day">Thu</div>
          <div className="days__day">Fri</div>
          <div className="days__day deactivated">Sat</div>
        </div>
        <div className="mainCalendar">
          {dates.map((week, i) => (
            <div key={i} className="mainCalendar__row">
              {week.map((date, j) => (
                <button
                  key={j}
                  disabled={[0, 6].includes(date.getDay())}
                  className={`mainCalendar__day ts2 ${
                    [0, 6].includes(date.getDay())
                      ? "deactivated"
                      : bookedDates
                          .map((date) => date.toDateString())
                          .includes(date.toDateString()) || date < currentDate
                      ? "booked"
                      : selectedDates
                          .map((date) => date.toDateString())
                          .includes(date.toDateString())
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => onDateClick(date)}
                >
                  {date.getDate()}
                </button>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
