import { createContext } from "react";
import { AdOption } from "../../../server/src/models/AdsOption";

// interface ActionType {
//   type: string;
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   payload: any;
// }

// const reducer = (state: GlobalContextType, action: ActionType) => {
//   switch (action.type) {
//     case "SET_AD_OPTIONS":
//       return {
//         ...state,
//         adOptions: action.payload,
//       };
//     default:
//       return state;
//   }
// };

export interface GlobalContextType {
  token: string;
  adOptions: AdOption[];
}

type DispatchType = (
  newContext:
    | GlobalContextType
    | ((oldContext: GlobalContextType) => GlobalContextType)
) => void;

export const initialGlobalContext: GlobalContextType = {
  token: "",
  adOptions: [],
};

export const GlobalContext = createContext<[GlobalContextType, DispatchType]>([
  initialGlobalContext,
  () => {
    return;
  },
]);
