import { BookingCard } from "components/cards/BookingCard";
import PrimaryCard from "components/cards/PrimaryCard";
import { GlobalContext } from "context/GlobalContext";
import { useContext } from "react";
import "styles/pages/index.scss";

export const Home = (): JSX.Element => {
  const [context] = useContext(GlobalContext);

  // useEffect(() => {
  //   setInterval(() => {
  //     console.log("context.adOptions.length", context.adOptions.length);
  //   }, 1000);
  // }, [context.adOptions.length]);

  return (
    <div className="homeWrapper">
      <div className="homeWrapper__header">
        <PrimaryCard>
          <div className="homeWrapper__header__title">
            <h1 className="ts1">Browser Classifieds</h1>
          </div>
          <div className="homeWrapper__header__body">
            <p className="ts2">
              We run one classified per day in our short, high-engagement daily
              newsletter. Your simple, text-based ad (up to 280 characters) will
              go out by email to 50k+ individual subscribers; our last email was
              opened and read by 20k+ smart, affluent readers.
            </p>
          </div>
        </PrimaryCard>
      </div>
      <div className="homeWrapper__body">
        {context.adOptions.map((bookingCard) => (
          <BookingCard key={bookingCard.id} {...bookingCard} />
        ))}
      </div>
    </div>
  );
};
