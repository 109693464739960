import { Link } from "react-router-dom";

import "styles/components/cards/bookingCard.scss";
import { AdOption } from "../../../../server/src/models/AdsOption";

// interface Props {
//   id: string;
//   number: number;
//   title: string;
//   description: string;
//   price: number;
//   bookText?: string;
// }

export const BookingCard = ({
  id,
  title,
  description,
  price,
  callToAction,
  numberOfDays,
}: AdOption): JSX.Element => {
  return (
    <div className="bookingCard">
      <div className="bookingCard__back__number">{numberOfDays}</div>
      <div className="bookingCard__header">
        <h1 className="ts1" dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className="bookingCard__body">
        <p className="bookingCard__body__description ts2">{description}</p>
        <h1 className="bookingCard__body__price ts1">
          $ {(price / 100).toFixed(2)}
        </h1>
      </div>
      <Link to={`/calendar/${id}`} className="bookingCard__book__button ts3">
        {callToAction}
      </Link>
    </div>
  );
};
