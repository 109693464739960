import { useEffect, useState } from "react";
import { Ad } from "../../../server/src/models/Ad";
import { GetCurrentAdAPI, UpdateAdInfoAPI } from "api";
import { Navigate, useParams } from "react-router-dom";
import { RootRoutes } from "App";
import { toast } from "react-toastify";
import PrimaryCard from "components/cards/PrimaryCard";

import "styles/pages/form.scss";
import { AdOption } from "../../../server/src/models/AdsOption";

export const FormPage = (): JSX.Element => {
  const { token } = useParams();

  if (!token) {
    return <Navigate to={RootRoutes.home} replace={true} />;
  }

  const [bookedAd, setBookedAd] = useState<Ad | null>(null);
  const [link, setLink] = useState("");
  const [body, setBody] = useState("");

  const getBookedAd = async () => {
    const { response, errors } = await GetCurrentAdAPI({
      auth_token: token,
    });

    if (errors) {
      errors.forEach((error) => toast.error(error.message));
      return;
    }

    if (!response || !response.success) {
      toast.error("Server not responding properly");
      return;
    }

    setBookedAd(response.ad);
    setLink(response.ad.link || "");
    setBody(response.ad.body || "");
  };

  const updateAdInfo = async () => {
    const { response, errors } = await UpdateAdInfoAPI({
      auth_token: token,
      link,
      body,
    });

    if (errors) {
      errors.forEach((error) => toast.error(error.message));
      return;
    }

    if (!response || !response.success) {
      toast.error("Server not responding properly");
      return;
    }

    toast.success("Ad info updated successfully");
    setBookedAd(response.ad);
  };

  useEffect(() => {
    getBookedAd();
  }, []);

  return !bookedAd ? (
    <>Loading....</>
  ) : (
    <div className="formPageWrapper">
      <div className="formWrapper">
        <PrimaryCard className="formWrapperPrimaryCard">
          <div className="infoWrapper">
            <h1 className="ts1 title">Ad Info</h1>
            <div className="ts2 info">
              <table>
                <tbody>
                  <tr>
                    <td>Ad ID</td>
                    <td>{bookedAd.id}</td>
                  </tr>
                  <tr>
                    <td>Ad email</td>
                    <td>{bookedAd.email}</td>
                  </tr>
                  <tr>
                    <td>Ad Dates</td>
                    <td>{new Date(bookedAd.date).toUTCString()}</td>
                  </tr>
                  <tr>
                    <td>Ad days</td>
                    <td>{(bookedAd.option as AdOption).numberOfDays}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="linkWrapper">
            <label className="ts2 linkLabel" htmlFor="adLink">
              Please enter the link you would like to use in your ad{" "}
              <span>(Required)</span>
            </label>
            <input
              className="linkInput"
              name="adLink"
              type="text"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </div>
          <div className="bodyWrapper">
            <label className="ts2 bodyLabel" htmlFor="adBody">
              Please enter the text of your ad below, up to 280 characters{" "}
              <span>(Required)</span>
            </label>
            <textarea
              className="bodyInput"
              name="adBody"
              value={body}
              onChange={(e) => setBody(e.target.value.slice(0, 280))}
            />
            <div className="ts2 bodyCharactersCount">{body.length}/280</div>
          </div>
          <div className="ts2 infoNoteWrapper">
            <p>
              Add asterisks around small segments of text (up to 6 words total)
              you would like to bold, and [square brackets] around the part of
              the text where you would like us to overlay your link (up to 6
              words). This question is required.
            </p>
          </div>
          <div className="submitWrapper">
            <button onClick={updateAdInfo}>Update</button>
          </div>
        </PrimaryCard>
      </div>
    </div>
  );
};
