import { Navigate, useParams } from "react-router-dom";
import { RootRoutes } from "App";
import PrimaryCard from "components/cards/PrimaryCard";
import "styles/pages/calendar.scss";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Calendar } from "components/common/Calendar";
import { GlobalContext } from "context/GlobalContext";
import { BookADayAPI, GetBookedDaysAPI } from "api";

const CalendarPage = (): JSX.Element => {
  const { id } = useParams();
  if (!id) {
    return <Navigate to={RootRoutes.home} />;
  }
  const [context] = useContext(GlobalContext);
  const [activateContinue, setActivateContinue] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [bookedDays, setBookedDays] = useState<string[]>([]);

  // if (!context.adOptions.length) {
  //   return <>Loading ...</>;
  // }

  const selectedClassified = context.adOptions.find(
    (option) => option.id === id
  );

  const onBack = () => {
    // window.history.back();
    console.log(window.history);

    if (window.history.state.idx > 0) {
      window.history.back();
    } else {
      window.location.href = RootRoutes.home;
    }
  };

  const onContinue = async () => {
    if (!selectedClassified || !activateContinue) {
      return toast.error("Please select a date");
    }

    const { response, errors } = await BookADayAPI({
      date: selectedDates[0].getTime(),
      option: selectedClassified.id,
    });
    if (errors) {
      errors.forEach((error) => toast.error(error.message));
      return;
    }
    if (!response || !response.success) {
      toast.error("Server not responding properly");
      return;
    }
    const link =
      selectedClassified.stripeLink + `?client_reference_id=` + response.ad.id;

    window.location.href = link;
  };

  const getBookedDays = async () => {
    const { response, errors } = await GetBookedDaysAPI();
    if (errors) {
      errors.forEach((error) => toast.error(error.message));
      window.location.href = RootRoutes.home;
      return;
    }
    if (!response || !response.success) {
      toast.error("Server not responding properly");
      window.location.href = RootRoutes.home;
      return;
    }
    setBookedDays(response.bookedDays);
  };

  useEffect(() => {
    setActivateContinue(false);
    getBookedDays();
  }, []);

  useEffect(() => {
    setActivateContinue(selectedDates.length > 0);
  }, [selectedDates]);

  return !selectedClassified ? (
    <>Loading ...</>
  ) : (
    <div className="calendarWrapper">
      <div className="calendarWrapper__content">
        <div className="back" onClick={onBack}>
          {"◂"}
        </div>
        <PrimaryCard className="calendarWrapper__content__browserClassified">
          <h1 className="ts1 calendarWrapper__content__browserClassified__title">
            Browser Classifieds
          </h1>
          <p className="ts2 calendarWrapper__content__browserClassified__body">
            Choose the date for your Browser classified. Our news letter are
            sent <b>5:00 PM</b> GMT+1.
          </p>
        </PrimaryCard>
        <PrimaryCard className="calendarWrapper__content__browserClassified">
          <Calendar
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            bookedDays={bookedDays}
          />
        </PrimaryCard>
        <PrimaryCard className="calendarWrapper__content__ticket">
          <div className="calendarWrapper_content_ticket_header">
            <h1
              className="ts1 calendarWrapper__content__ticket__title"
              dangerouslySetInnerHTML={{
                __html: selectedClassified?.title || "Option Title",
              }}
            />
            <p className="ts2 calendarWrapper__content__ticket__body">
              Please Select the first date you would like your browser
              classified to start at
            </p>
          </div>

          <div className="calendarWrapper__content__ticket__infoWrapper">
            <p>
              Date:{" "}
              {selectedDates.length > 0 ? (
                <span className="selected_date">
                  {selectedDates[0].toDateString()}
                </span>
              ) : (
                <span className="select_date">select a date</span>
              )}
            </p>
            <p className="ts1 price">
              $ {(selectedClassified.price / 100).toFixed(2)}
            </p>
          </div>

          <button
            className={`calendarWrapper__content__ticket__continueButton ts3 ${
              !activateContinue && "disabled"
            }`}
            onClick={onContinue}
          >
            Continue
          </button>
        </PrimaryCard>
      </div>
    </div>
  );
};

export { CalendarPage as Calendar };
