import { AuthenticatedPropsGenerator, Response } from "types";
import {
  getAuthenticatedApi,
  getUnauthenticatedApi,
  postUnauthenticatedApi,
  putAuthenticatedApi,
} from "./apiGenerator";
import {
  BookADayAPIRequest,
  BookADayAPIResponse,
  ConfirmBookingAPIRequest,
  ConfirmBookingAPIResponse,
  GetAvailableOptionsAPIResponse,
  GetBookedDaysAPIResponse,
  GetCurrentAdAPIResponse,
  UpdateAdInfoAPIRequest,
  UpdateAdInfoAPIResponse,
} from "./types/general";

//! POST
export const BookADayAPI = (
  body: BookADayAPIRequest
): Promise<Response<BookADayAPIResponse>> =>
  postUnauthenticatedApi<BookADayAPIRequest, BookADayAPIResponse>({
    url: "/book-a-day",
    body,
  });

export const ConfirmBookingAPI = (
  body: ConfirmBookingAPIRequest
): Promise<Response<ConfirmBookingAPIResponse>> =>
  postUnauthenticatedApi<ConfirmBookingAPIRequest, ConfirmBookingAPIResponse>({
    url: "/confirm-booking",
    body,
  });

//! GET
export const GetBookedDaysAPI = (): Promise<
  Response<GetBookedDaysAPIResponse>
> =>
  getUnauthenticatedApi<GetBookedDaysAPIResponse>({
    url: "/booked-dates",
  });

export const GetAvailableOptionsAPI = (): Promise<
  Response<GetAvailableOptionsAPIResponse>
> =>
  getUnauthenticatedApi<GetAvailableOptionsAPIResponse>({
    url: "/available-options",
  });

export const GetCurrentAdAPI = ({
  auth_token,
}: AuthenticatedPropsGenerator<unknown>): Promise<
  Response<GetCurrentAdAPIResponse>
> =>
  getAuthenticatedApi<GetCurrentAdAPIResponse>({
    url: "/current-ad",
    auth_token,
  });

//! PUT
export const UpdateAdInfoAPI = (
  body: AuthenticatedPropsGenerator<UpdateAdInfoAPIRequest>
): Promise<Response<UpdateAdInfoAPIResponse>> =>
  putAuthenticatedApi<UpdateAdInfoAPIRequest, UpdateAdInfoAPIResponse>({
    url: "/update-ad-info",
    auth_token: body.auth_token,
    body,
  });
