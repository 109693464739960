import { ToastContainer, toast } from "react-toastify";

import { Navigate, Route, Routes } from "react-router-dom";
import { Home } from "pages";

import "styles/global.scss";
import "react-toastify/dist/ReactToastify.css";
import { Calendar } from "pages/calendar.page";
import { Checkout } from "pages/checkout.page";
import { useEffect, useState } from "react";
import { GlobalContext, initialGlobalContext } from "context/GlobalContext";
import { GetAvailableOptionsAPI } from "api";
import { FormPage } from "pages/form.page";

export enum RootRoutes {
  home = "/",
  calendar = "/calendar/:id",
  booking = "/booking/:id",
  checkout = "/checkout/:id",

  form = "/form/:token",
}

function App(): JSX.Element {
  const [context, setContext] = useState(initialGlobalContext);
  const getAdOptions = async () => {
    if (context.adOptions.length) {
      return;
    }
    const { response, errors } = await GetAvailableOptionsAPI();
    if (errors) {
      errors.forEach((error) => toast.error(error.message));
      return;
    }
    if (!response || !response.success) {
      toast.error("Server not responding properly");
      return;
    }

    setContext((prevContext) => ({
      ...prevContext,
      adOptions: response.options,
    }));

    // context.adOptions = response.options;

    // context.adOptions =
  };

  useEffect(() => {
    getAdOptions();
  }, []);
  return (
    <>
      <GlobalContext.Provider value={[context, setContext]}>
        <ToastContainer position="top-right" draggable />
        <Routes>
          <Route path={RootRoutes.booking} element={<></>} />
          <Route path={RootRoutes.checkout} element={<Checkout />} />
          <Route path={RootRoutes.calendar} element={<Calendar />} />
          <Route path={RootRoutes.form} element={<FormPage />} />
          <Route path={RootRoutes.home} element={<Home />} />

          {/* Redirection */}
          <Route path="*" element={<Navigate to={RootRoutes.home} />} />
        </Routes>
      </GlobalContext.Provider>
    </>
  );
}

export default App;
