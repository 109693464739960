import axios from "axios";
import { errorParser } from "./errors";
import { getHost } from "./getHost";

import {
  Response,
  GetUnauthenticatedApiRequest,
  GetAuthenticatedApiRequest,
  PostAuthenticatedApiRequest,
  PostUnauthenticatedApiRequest,
  PutAuthenticatedApiRequest,
  DeleteAuthenticatedApiRequest,
} from "types";

//? GET - START
export async function getUnauthenticatedApi<TResponse>({
  url,
  params,
}: GetUnauthenticatedApiRequest): Promise<Response<TResponse>> {
  try {
    const host = getHost();
    const response = await axios.get(`${host}${url}`, {
      params: params || {},
      headers: { "Content-Type": "application/json" },
    });

    return { response: response.data };
  } catch (error) {
    return {
      errors: errorParser(error),
    };
  }
}

export async function getAuthenticatedApi<TResponse>({
  url,
  params,
  auth_token,
}: GetAuthenticatedApiRequest): Promise<Response<TResponse>> {
  try {
    const host = getHost();
    const response = await axios.get(`${host}${url}`, {
      params: params || {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth_token}`,
      },
    });

    return { response: response.data };
  } catch (error) {
    return {
      errors: errorParser(error),
    };
  }
}
//? GET - END

//? POST - START
export async function postUnauthenticatedApi<TRequest, TResponse>({
  url,
  params,
  body,
}: PostUnauthenticatedApiRequest<TRequest>): Promise<Response<TResponse>> {
  try {
    const host = getHost();
    const response = await axios.post(`${host}${url}`, body, {
      params: params || {},
      headers: {
        "Content-Type": "application/json",
      },
    });

    return { response: response.data };
  } catch (error) {
    return {
      errors: errorParser(error),
    };
  }
}

export async function postAuthenticatedApi<TRequest, TResponse>({
  url,
  params,
  body,
  auth_token,
  contentType = "application/json",
}: PostAuthenticatedApiRequest<TRequest>): Promise<Response<TResponse>> {
  try {
    const host = getHost();
    const response = await axios.post(`${host}${url}`, body, {
      params: params || {},
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${auth_token}`,
      },
    });

    return { response: response.data };
  } catch (error) {
    return {
      errors: errorParser(error),
    };
  }
}
//? POST - END

//? PUT - START
// export async function putUnauthenticatedApi<TRequest, TResponse>({
//   url,
//   params,
//   body,
// }: PutUnauthenticatedApiRequest<TRequest>): Promise<Response<TResponse>> {
//   try {
//     const host = getHost();
//     const response = await axios.put(`${host}${url}`, body, {
//       params: params || {},
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });

//     return { response: response.data };
//   } catch (error) {
//     return {
//       errors: errorParser(error),
//     };
//   }
// }

export async function putAuthenticatedApi<TRequest, TResponse>({
  url,
  params,
  body,
  auth_token,
  contentType = "application/json",
}: PutAuthenticatedApiRequest<TRequest>): Promise<Response<TResponse>> {
  try {
    const host = getHost();
    const response = await axios.put(`${host}${url}`, body, {
      params: params || {},
      headers: {
        //   Accept: "*/*",
        "Content-Type": contentType,
        Authorization: `Bearer ${auth_token}`,
      },
    });

    return { response: response.data };
  } catch (error) {
    return {
      errors: errorParser(error),
    };
  }
}
//? PUT - END

//? DELETE - START
export async function deleteAuthenticatedApi<TResponse>({
  url,
  params,
  auth_token,
}: DeleteAuthenticatedApiRequest): Promise<Response<TResponse>> {
  try {
    const host = getHost();
    const response = await axios.delete(`${host}${url}`, {
      params: params || {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth_token}`,
      },
    });

    return { response: response.data };
  } catch (error) {
    return {
      errors: errorParser(error),
    };
  }
}
//? DELETE - END
